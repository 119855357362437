import React, { useState, useEffect } from 'react';
import EmailForm from '../components/EmailForm';
import Quiz from '../components/Quiz';
import ThankYou from '../components/ThankYou';
import prize1 from '../assets/Nagrody-konkurs-masaz.png'; // Import the image file
import prize2 from '../assets/Nagrody-konkurs-szkolenie.png';
import regulamin from '../assets/regulamin.pdf'; // Import the regulations file

function Home() {
  const [email, setEmail] = useState(() => localStorage.getItem('userEmail') || '');
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [result, setResult] = useState(null);
  const [openAnswer, setOpenAnswer] = useState('');

  useEffect(() => {
    if (email) {
      localStorage.setItem('userEmail', email);
    }
  }, [email]);

  const handleEmailSubmit = (submittedEmail) => {
    setEmail(submittedEmail);
    setQuizStarted(true);
  };

  const handleQuizComplete = (quizResult, openAnswer) => {
    setResult(quizResult);
    setOpenAnswer(openAnswer);
    setQuizCompleted(true);
  };

  const handleQuizQuit = () => {
    setEmail('');
    setQuizStarted(false);
    localStorage.removeItem('userEmail');
  };

  const handleRestart = () => {
    setEmail('');
    setQuizStarted(false);
    setQuizCompleted(false);
    setResult(null);
    localStorage.removeItem('userEmail');
  };

  return (
    <div className="app-container">
      {!email && !quizStarted && (
        <div className="welcome-container">
          <h2 className="welcome-title">OLX Praca z korzyścią dla wszystkich</h2>
          <h3>
            Wokół pokoleń Silversów i Zetek narosło wiele mitów. 
          </h3>
          <p className="welcome-text">
            Czy rzeczywiście w każdym micie jest chociaż ziarnko prawdy? Wiele z nich możemy obalić, odwołując się do wyników naszych badań. <strong>Weź udział w quizie i przekonaj się, że nie warto myśleć stereotypowo...</strong> ale warto rekrutować międzypokoleniowo, tworząc zróżnicowane zespoły!
          </p>
          <p className="welcome-text">
            Czekają na Ciebie pytania zamknięte oraz jedno pytanie otwarte. <strong>Nie pomijaj go, bo najciekawsze odpowiedzi zostaną nagrodzone!</strong> 
          </p>
          <div className="email-form-container">
            <h3>KONKURS ZAKOŃCZONY</h3>
 
<p>Dziękujemy za udział w konferencji Leniwce HR i za wszystkie wartościowe spotkania.</p>
<br/>
<p><strong>Wziąłeś udział w konkursie?</strong></p>

<p>Koniecznie sprawdzaj, czy wygrałeś!</p>

<p>Jury konkursowe wkrótce skontaktuje się mailowo z wybranymi osobami.</p>
            
          </div>
          <div className="regulations">Administratorem danych osobowych jest Grupa OLX sp. z o.o. z siedzibą w Poznaniu. Twoje dane będą przetwarzane w celu przeprowadzenia konkursu.  Więcej informacji o przetwarzaniu Twoich danych osobowych, w tym o innych celach ich przetwarzania oraz przysługujących Ci prawach takich jak w szczególności prawo dostępu, poprawiania, usunięcia danych lub złożenia skargi do PUODO, znajdziesz w regulaminie konkursu pod linkiem <a href={regulamin}>Regulamin</a>.</div>
          <div className="prize-container">
            <h2 className="prize-title">Nagrody</h2>
            <div className="prize-description">
              Rozwijaj się w pracy i odpoczywaj po pracy! Weź udział w quizie i zgarnij <strong>voucher na praktyczne szkolenie lub na tajski masaż</strong> 
            </div>
            <div className="prize-images">
              <img src={prize1} alt="Nagroda masaz" className="prize-image" />
              <img src={prize2} alt="Nagroda szkolenie" className="prize-image" />
            </div>
          </div>
        </div>
      )}
      {email && !quizCompleted && (
        <Quiz email={email} onComplete={handleQuizComplete} onQuit={handleQuizQuit} />
      )}
      {quizCompleted && result && <ThankYou result={result} openAnswer={openAnswer} onRestart={handleRestart} />}
    </div>
  );
}

export default Home;