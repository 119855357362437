import React, { useState } from 'react';
import axios from 'axios';
import arrowLeftIcon from '../assets/ion-arrow-back-outline-icon.svg';
import arrowRightIcon from '../assets/ion-arrow-forward-outline-icon.svg';
import closeIcon from '../assets/ion-close-icon.svg';
import okIcon from '../assets/ion-checkmark-icon.svg';

// Define the quiz questions and their correct answers
const questions = [
  {
    text: "Jedną z ważnych cech Silversów jest lojalność wobec pracodawcy. Jak długo pracują średnio u jednego pracodawcy?",
    options: [
      { text: "3 lata w przypadku Baby Boomersów i 2 lata w przypadku pokolenia X", isCorrect: false },
      { text: "5 lat w przypadku Baby Boomersów i 3 lata w przypadku pokolenia X", isCorrect: false },
      { text: "8 lat w przypadku Baby Boomersów i 7 lat w przypadku pokolenia X", isCorrect: false },
      { text: "9,1 roku w przypadku Baby Boomersów i 4,3 roku w przypadku pokolenia X", isCorrect: true } // Correct answer
    ]
  },
  {
    text: "Zatrudnianie Silversów wzbudza pewne obawy ze względu na stereotypy związane z osobami 50+. Czy słusznie? Wskaż stwierdzenie na temat pokolenia Silversów, które z pewnością NIE jest prawdziwe.",
    options: [
      { text: "Silversi cenią sobie stałość, ale nie rutynę. Lubią pracować w jednym miejscu, ale jednocześnie często podejmują wyzwania", isCorrect: false },
      { text: "Silversi podchodzą do pracy z szacunkiem i zaangażowaniem.", isCorrect: false },
      { text: "Silversi ze względu na wiek nie są wydajni i pracują gorzej niż przedstawiciele innych pokoleń.", isCorrect: true }, // Correct answer
      { text: "Mimo wieku, w praktyce nie widać różnić między średnim okresem chorowania starszych i młodszych pracowników.", isCorrect: false }
    ]
  },
  {
    text: "Pokolenie Z powoduje realne zmiany na rynku pracy! Jakie tendencje dostrzegają pracodawcy?",
    options: [
      { text: "40% pracodawców uważa, że przedstawiciele pokolenia Z spowodowali większe rozleniwienie się pracowników w firmie.", isCorrect: false },
      { text: "50% pracodawców uważa, że pokolenie Z wpłynęło na bardziej elastyczne podejście firmy do świadczenia pracy.", isCorrect: true }, // Correct answer
      { text: "38% pracodawców deklaruje, że dzięki Zetkom mogą zrezygnować z zatrudniania bardziej doświadczonych, ale też bardziej wymagających finansowo pracowników.", isCorrect: false },
      { text: "65% pracodawców zauważyło, że wraz z wkroczeniem Zetek na rynek pracy zmieniły się także reguły awansów finansowych.", isCorrect: false }
    ]
  },
  {
    text: "Stereotypowa Zetka niczym się nie przejmuje i niechętnie inwestuje energię w rozwój zawodowy. To krzywdzący mit! Dlaczego możliwości rozwoju są ważne dla Zetek?",
    options: [
      { text: "Zetki chcą zdobywać nowe umiejętności i awansować, by trafić do miejsca, które będzie dźwignią do ich kariery zawodowej.", isCorrect: true }, // Correct answer
      { text: "Zetki chcą utrzymywać swoje umiejętności na aktualnym poziomie, by czuć się potrzebne.", isCorrect: false },
      { text: "Zetki rozszerzają swoje umiejętności, bo nie chcą być onieśmielone pędem technologicznym.", isCorrect: false },
      { text: "Zetki dbają o możliwości rozwoju, by móc chwalić się nimi na Instagramie.", isCorrect: false }
    ]
  },
  {
    text: "Skoro wiesz już, że warto budować międzypokoleniowe zespoły, zaproponuj 2 wyjątkowe benefity, które przyciągną Silversów i Zetki do Twojej organizacji (1 dla Silversów, 1 dla Zetek). Jeśli potrzebujesz inspiracji, porozmawiaj o rekrutacji międzypokoleniowej z naszym zespołem na stoisku OLX Praca.",
    type: "open"
  }
];

function Quiz({ email, onComplete, onQuit }) {
  // State for storing answers, current question, selected option, and transition state
  const [answers, setAnswers] = useState(Array(questions.length).fill(''));
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [openAnswer, setOpenAnswer] = useState('');

  // Modify the allQuestionsAnswered function
  const allQuestionsAnswered = () => {
    return answers.slice(0, -1).every(answer => answer !== '') && openAnswer !== '';
  };

  // Handle when an answer is selected
  const handleAnswer = (answer) => {
    setSelectedOption(answer);
    setIsTransitioning(true);

    setTimeout(() => {
      const newAnswers = [...answers];
      newAnswers[currentQuestion] = answer;
      setAnswers(newAnswers);

      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      }
      setIsTransitioning(false);
      setSelectedOption(null);
    }, 500);
  };

  // Calculate the number of correct answers
  const calculateResult = () => {
    let correctCount = 0;
    answers.forEach((answer, index) => {
      const question = questions[index];
      if (question.options) {
        const selectedOption = question.options.find(option => option.text === answer);
        if (selectedOption && selectedOption.isCorrect) {
          correctCount++;
        }
      }
    });
    return correctCount.toString().concat(" / ", (questions.length - 1).toString()); // Return the count of correct answers
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!allQuestionsAnswered()) {
      alert('Proszę odpowiedzieć na wszystkie pytania przed zakończeniem quizu.');
      return;
    }

    try {
      const result = calculateResult();
      const response = await axios.post('https://api.leniwceolx.pl/leniwce-hr/public/api/quiz', {
        email,
        answers: [...answers.slice(0, -1), openAnswer],
        result: result
      });
      onComplete(result, openAnswer); // Pass openAnswer to onComplete
      console.log('Quiz submitted successfully:', response.data);
    } catch (error) {
      console.error('Error submitting quiz:', error);
    }
  };

  // Add handleOpenAnswer function
  const handleOpenAnswer = (event) => {
    setOpenAnswer(event.target.value);
  };

  // Render the quiz component
  return (
    <div className="quiz-container">
      {/* Display current question number */}
      <div className="question-counter">
        Pytanie {currentQuestion + 1}/{questions.length}
      </div>
      
      {/* Display current question text */}
      <h2 className="question-text">{questions[currentQuestion].text}</h2>
      
      {/* Render question based on type */}
      {questions[currentQuestion].type === 'open' ? (
        <div className="open-question-container">
          <textarea
            value={openAnswer}
            onChange={handleOpenAnswer}
            placeholder="Wpisz swoją odpowiedź tutaj..."
            rows={5}
            disabled={isTransitioning}
          />
        </div>
      ) : (
        questions[currentQuestion].options.map((option, index) => (
          <div key={index} className="option-container">
            <input
              type="radio"
              id={`option-${index}`}
              name={`question-${currentQuestion}`}
              value={option.text}
              checked={answers[currentQuestion] === option.text || selectedOption === option.text}
              onChange={() => handleAnswer(option.text)}
              disabled={isTransitioning}
            />
            <label 
              htmlFor={`option-${index}`}
              className={selectedOption === option.text ? 'selected' : ''}
            >
              {option.text}
            </label>
          </div>
        ))
      )}
      
      {/* Navigation buttons */}
      <div className="button-container">
        {/* Previous question button */}
        {currentQuestion > 0 && (
          <button 
            onClick={() => setCurrentQuestion(currentQuestion - 1)} 
            className="prev-button"
            disabled={isTransitioning}
          >
            &laquo; Poprzednie
          </button>
        )}
        
        {/* Submit or Next question button */}
        {currentQuestion === questions.length - 1 ? (
          <button 
            onClick={handleSubmit} 
            className="submit-button"
            disabled={isTransitioning || !allQuestionsAnswered()}
          >
            <img src={okIcon} alt="Zakończ quiz"  /> Zakończ quiz
          </button>
        ) : (
          <button 
            onClick={() => setCurrentQuestion(currentQuestion + 1)} 
            className="next-button"
            disabled={isTransitioning}
          >
            Następne &raquo;
          </button>
        )}
      </div>

      { !allQuestionsAnswered() && (
          <div className="not-all-answered">Aby zakończyć quiz odpowiedz na wsyzstkie pytania</div>
      )}

      {/* Quit button */}
      <div className="quit-button-container">
        <button 
          onClick={onQuit} 
          className="quit-button"
          disabled={isTransitioning}
        >
          <img src={closeIcon} alt="Wyjdź i zmień email"  /> Wyjdź i zmień email
        </button>
      </div>
    </div>
  );
}

export default Quiz;