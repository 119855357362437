import React from 'react';
import prize1 from '../assets/Nagrody-konkurs-masaz.png'; // Import the image file
import prize2 from '../assets/Nagrody-konkurs-szkolenie.png';

function ThankYou({ result, onRestart, openAnswer }) {
  return (
    <div className="thank-you-container">
      <h2 className="thank-you-title">Dziękujemy za wypełnienie quizu!</h2>
      <div className="result-container">
        <h3 className="result-title">Twój wynik:</h3>
        <div className="result-badge">
          <div className="result-badge-text">Poprawnych odpowiedzi:</div>
          <div className="result-badge-value">{result}</div>
        </div>
        <h3 className="open-answer-title">Twoja odpowiedź na ostatnie pytanie:</h3>
        <p className="open-answer">{openAnswer}</p>
      </div>
      <div className="prize-container">
        <h2 className="prize-title">Nagrody</h2>
        <div className="prize-description">
          Rozwijaj się w pracy i odpoczywaj po pracy! Weź udział w quizie i zgarnij <strong>voucher na praktyczne szkolenie lub na tajski masaż</strong>
        </div>
        <div className="prize-images">
          <img src={prize1} alt="Nagroda masaz" className="prize-image" />
          <img src={prize2} alt="Nagroda szkolenie" className="prize-image" />
        </div>
      </div>
      <button onClick={onRestart} className="restart-button">Rozpocznij ponownie</button>
    </div>
  );
}

export default ThankYou;