import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import logo from './assets/Logo-olx-praca.png'; // Import the image file
import regulamin from './assets/regulamin.pdf'; // Import the regulations file

function Header() {
  return (
    <header className="app-header">
      <div className="app-logo">
        <img src={logo} alt="OLX zawodowo" className="logo-image" />
      </div>
    </header>
  );
}

function Footer() {
  return (
    <footer className="app-footer">
      <div className="app-footer-text">
        OLX-praca © 2024. Wszelkie prawa zastrzeżone. <a href={regulamin}>Regulamin</a>
      </div>
    </footer>
  );
}

function App() {
  return (
    <Router basename="/">

        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />

    </Router>
  );
}

export default App;